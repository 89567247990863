import React from 'react'
import { connect, store, handlers } from '../../Store'
import { CustomerFields } from '../../Beauties'
import { MRSPEX_PARTNER_ENTERPRISE_ID } from '../../Settings'
import { sortByCategoryOrderIndex, sortByOrderIndex, sortBy, extractFromHtml } from '../../Utils'

const CustomerFieldsWrapper = props => {
  let {
    routeName,
    companies,
    selectedCompanyId,
    customerFields,
    selectedServiceId,
    selectedCourseId,
    timezones,
    enterpriseId,
    isGuest,
    coursesList,
    selectedEventId,
    extraParticipants,
    attributes,
    setFileLoading,
    fileLoading,
    booker,
    customisations,
    locale,
    customerFieldsBeforeConfirmation,
    customerFieldsOnService,
    formRef
  } = props
  customerFieldsBeforeConfirmation = customerFieldsBeforeConfirmation || {}
  customerFieldsOnService = customerFieldsOnService || {}
  const { isEnterprise, hideFilledFields } = attributes || {}
  const { payload: customisationsPayload } = customisations || {}
  const { settings: customisationsSettings } = customisationsPayload || {}
  const {
    hideDisclaimer,
    disclaimerType: disclaimerTypeCustomisation,
    disclaimerText: disclaimerTextCustomisation
  } = customisationsSettings || {}
  const isBooker = booker && booker.id
  const isActive = routeName === 'customerFields'
  const isPrevious = ['payments', 'confirm', 'success'].includes(routeName)
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const { settings: companySettings = {} } = company
  let { customTermsAndConditionsUrl, customPrivacyPolicyUrl, disclaimerConfig } = companySettings
  const selectedItemId = selectedServiceId || selectedCourseId
  const selectedEvent = coursesList.find(event => event.id === selectedEventId)
  const filterByDefaultId = isGuest
    ? []
    : ['email']
  let filteredCustomerFields = customerFields
    .filter(item =>
      item.companyId === selectedCompanyId &&
      item.isAfterAuth &&
      ((selectedServiceId && item.areAllServicesSelected) || item.serviceIds.includes(selectedItemId) || (selectedCourseId && item.areAllCoursesSelected) || item.courseIds.includes(selectedItemId)) &&
      !filterByDefaultId.includes(item.defaultId) &&
      (item.type !== 'FILES' || isBooker || !item.hasOverwrite)
    )
    .sort(sortByOrderIndex)
    .sort(sortBy('internalId', false, true)) // put globals last, but ignore default data fields, bc they will not get ordered by orderIndex if you apply this sort without last param
    .sort(sortByCategoryOrderIndex)
  if (isActive) {
    const nameCustomerFields = filteredCustomerFields.filter(item => ['firstName', 'lastName', 'company'].includes(item.defaultId))
    if (nameCustomerFields.length === 0) {
      filteredCustomerFields = [
        ...(customerFields.filter(item => ['firstName', 'lastName', 'company'].includes(item.defaultId))
          .sort(sortByOrderIndex)
          .sort(sortBy('internalId', false, true)) // put globals last, but ignore default data fields, bc they will not get ordered by orderIndex if you apply this sort without last param
          .sort(sortByCategoryOrderIndex) || []),
        ...filteredCustomerFields
      ]
    }
  }
  if (hideFilledFields) {
    const formCustomerFields = {
      ...customerFieldsBeforeConfirmation,
      ...customerFieldsOnService
    }
    filteredCustomerFields = filteredCustomerFields.filter(item => {
      const formItem = formCustomerFields[`customerField${item.id}`]
      if (item.type === 'CHECKBOX') return true // never hide checkboxes
      if (item.type === 'FILES') return !item.value && item.selected && item.selected.length === 0
      if (item.type === 'PHONE') return !item.value && !item.phone
      if (formItem) return !formItem.value
      return !item.value
    })
  }
  const onSubmit = cb => {
    let additionalParticipantsCount = parseInt(extraParticipants, 10)
    if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

    if (selectedEvent && selectedEvent.extraPersonsPerParticipant < additionalParticipantsCount) return

    const form = store.getState().forms.customerFieldsBeforeConfirmation
    handlers.customerFieldsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  // Hardcoded fix for MisterSpex and their partners when we have integration on the MisterSpex's website
  // we must show aways the checkbox for terms and conditions
  if (isEnterprise && enterpriseId && MRSPEX_PARTNER_ENTERPRISE_ID.includes(enterpriseId)) {
    customTermsAndConditionsUrl = 'MISTERSPEX_PARTNER_TERMS'
    customPrivacyPolicyUrl = 'MISTERSPEX_PARTNER_TERMS'
  }

  // disclaimer
  disclaimerConfig = disclaimerConfig || {}
  if (disclaimerTypeCustomisation && disclaimerTextCustomisation) {
    disclaimerConfig.active = true
    disclaimerConfig.displayOn = disclaimerTypeCustomisation
    disclaimerConfig.text = disclaimerTextCustomisation
  }
  if (hideDisclaimer) disclaimerConfig = {}
  const {
    active: hasDisclaimer,
    text: disclaimerText,
    displayOn: disclaimerPlacement
  } = disclaimerConfig || {}
  const shouldDisplayDisclaimer = !!hasDisclaimer && disclaimerPlacement === 'CUSTOMER_DETAIL_FORM' && !!extractFromHtml(disclaimerText)

  return (
    <CustomerFields
      isActive={isActive}
      isPrevious={isPrevious}
      isGuest={isGuest}
      company={company}
      customTermsAndConditionsUrl={customTermsAndConditionsUrl}
      customPrivacyPolicyUrl={customPrivacyPolicyUrl}
      filteredCustomerFields={filteredCustomerFields}
      timezones={timezones}
      enterpriseId={enterpriseId}
      selectedEvent={selectedEvent}
      onSubmit={onSubmit}
      formRef={formRef}
      setFileLoading={setFileLoading}
      fileLoading={fileLoading}
      isBooker={isBooker}
      shouldDisplayDisclaimer={shouldDisplayDisclaimer}
      disclaimerText={disclaimerText}
      locale={locale}
    />
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  selectedServiceId: state.services.selectedId || null,
  selectedCourseId: state.courses.selectedId || null,
  customerFields: state.customerFields.list || [],
  timezones: state.timezones.list || [],
  enterpriseId: state.enterprise.id || null,
  isGuest: !state.auth.tokens,
  coursesList: state.slots.coursesList || [],
  selectedEventId: state.slots.selectedEventId || null,
  extraParticipants: state.courses.extraParticipants || '',
  attributes: state.attributes || [],
  booker: state.account.booker || null,
  customisations: state.customisations || {},
  locale: state.app.locale,
  customerFieldsBeforeConfirmation: state.forms.customerFieldsBeforeConfirmation,
  customerFieldsOnService: state.forms.customerFieldsOnService
})

export default connect(maps)(CustomerFieldsWrapper)
