import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_API_KEY } from '../../Settings'
import { connect, handlers } from '../../Store'
import { PaymentStripe } from '../../Beauties'
import { getRouteQuery, getDiscountedPrice } from '../../Utils'

let stripePromise

const PaymentStripeWrapper = props => {
  let {
    router,
    services,
    courses,
    selectedCompanyId,
    selectedOfferId,
    companies,
    offers,
    extraParticipants,
    locale,
    clientSecret,
    showFooterBackButton,
    countries
  } = props
  showFooterBackButton = !!JSON.parse(showFooterBackButton)
  const isActive = router.name === 'paymentStripe'
  const isPrevious = ['success', 'auth', 'authLogin', 'authRegister', 'authForgotten', 'authConfirmation', 'authChangePhone', 'authGuestBooking', 'authEnforcePasswordChange'].includes(router.name)
  const routeQuery = getRouteQuery(router)
  const { selectedId: selectedServiceId, list: servicesList } = services || {}
  const { selectedId: selectedCourseId, list: coursesList } = courses || {}
  let selectedService = {}
  if (selectedServiceId) selectedService = servicesList.find(item => item.id === selectedServiceId)
  if (selectedCourseId) selectedService = coursesList.find(item => item.id === selectedCourseId)
  const offer = selectedOfferId ? offers.find(item => item.id === selectedOfferId) : null
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const { stripeFeePercentage, stripeClientId: stripeAccount } = company || {}
  const country = countries.find(({ code }) => code.toLowerCase() === locale.split('-')[1])
  const { hasStripeFeesNotAllowed } = country || {}
  const discountedPrice = offer && selectedService && selectedService.price ? getDiscountedPrice(selectedService.price, offer.discount) : null
  let price = discountedPrice || (selectedService && selectedService.price) || 0
  let additionalParticipantsCount = parseInt(extraParticipants, 10)
  if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0
  if (selectedService && selectedService.extraPersonsPerParticipant && additionalParticipantsCount > 0) price = price * (additionalParticipantsCount + 1)
  if (selectedService) {
    selectedService.taxProcent = 0
    if (!hasStripeFeesNotAllowed && ['paymentOnlinePayment', 'paymentStripe'].includes(router.name)) selectedService.taxProcent = stripeFeePercentage || 0
    selectedService.taxPrice = price - getDiscountedPrice(price, selectedService.taxProcent)
  }

  useEffect(() => {
    if (!isActive) return
    handlers.stripePaymentIntentGet()
    stripePromise = loadStripe(STRIPE_API_KEY, { stripeAccount })
  }, [isActive, stripeAccount])

  return (
    <PaymentStripe
      isActive={isActive}
      isPrevious={isPrevious}
      routeQuery={routeQuery}
      selectedService={selectedService}
      locale={locale.substr(0, 2)}
      showFooterBackButton={showFooterBackButton}
      clientSecret={clientSecret}
      stripePromise={stripePromise}
    />
  )
}

const maps = state => ({
  router: state.router || {},
  services: state.services || {},
  courses: state.courses || {},
  offers: state.offers.list || [],
  companies: state.companies.list || [],
  countries: state.countries || [],
  selectedCompanyId: state.companies.selectedId || null,
  selectedOfferId: state.slots.selectedOfferId || null,
  extraParticipants: state.courses.extraParticipants || '',
  showFooterBackButton: state.attributes.showFooterBackButton || false,
  locale: state.app.locale || 'en-gb',
  clientSecret: state.stripe.clientSecret || null
})

export default connect(maps)(PaymentStripeWrapper)
