export const offersTransform = offers => {
  if (!offers) return []
  return offers.map(item => {
    const { interval, publishInterval } = item
    const { begin, end } = interval || {}
    const { begin: publishBegin, end: publishEnd } = publishInterval || {}
    const result = {
      ...item,
      from: begin,
      until: end,
      publishFrom: publishBegin,
      publishUntil: publishEnd
    }
    delete result.interval
    return result
  })
}
