import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps, dangerousHTML } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './Input.css'

class Input extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    const { name, form, formName, onChange, onChangeAddon } = this.props
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    const {
      label,
      hintText,
      addon,
      type,
      name,
      value,
      mandatory,
      disabled,
      hideError,
      className,
      limit,
      form = {}
    } = this.props
    let { placeholder = form.placeholder } = this.props

    if (!focused && (value === undefined || value === '' || form.value === '')) placeholder = label || form.label

    if (mandatory || form.mandatory) placeholder = `${placeholder} *`
    const inputClassNames = ['ta-form-control']
    if (addon || limit) inputClassNames.push('has-addon')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          <input
            className={inputClassNames.join(' ')}
            ref={ref => { this.ref = ref }}
            type={type || form.type || 'text'}
            name={name}
            value={value !== undefined ? value : form.value}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            maxLength={limit && limit}
            disabled={disabled || form.disabled}
            autoComplete='off'
          />
          {addon &&
            <div className='ta-form-control__addon'>
              {addon}
            </div>
          }
          {limit &&
            <div className={`ta-form-control__addon ${form.value.length > 0 && 'move-bottom'}`}>
              {`${form.value.length} / ${limit}`}
            </div>
          }
          {(hintText || limit) &&
            <div className='ta-form-control__hint'>
              {hintText && dangerousHTML(hintText)}
            </div>
          }
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  limit: PropTypes.number,
  form: PropTypes.object,
  className: PropTypes.string
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(Input), FormContext)
