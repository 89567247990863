import { offersTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: []
}

reducer.handlers = (dispatch, actions) => ({
  offersReset: () => dispatch(actions.OFFERS_RESET),
  offersPopulate: offers => dispatch(actions.OFFERS_POPULATE, offersTransform(offers))
})

reducer.OFFERS_RESET = state => reducer.initialState

reducer.OFFERS_POPULATE = (state, offers) => ({
  ...state,
  list: offers
})

export default reducer
