import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  HeaderWrapper,
  FooterWrapper,
  PopupWrapper,
  NotFoundWrapper,
  ConflictWrapper,
  LocationsWrapper,
  ServicesWrapper,
  AvailabilityWrapper,
  AuthWrapper,
  CustomerFieldsWrapper,
  ConfirmWrapper,
  SuccessWrapper,
  EnterpriseLogoWrapper,
  PaymentsWrapper,
  PaymentOnlinePaymentWrapper,
  PaymentStripeWrapper,
  UpSellingWrapper,
  BookedOutWrapper,
  ProgressBarWrapper,
  DebugOptionsWrapper,
  CookieConsentMessageWrapper
} from '../../Beasts'
import { FontAwesome5, Link, Loader, EnterpriseLoader, LoadingPlaceholder } from '../../Common'

import './App.css'

const App = props => {
  const {
    hasOverwriteCss,
    isPending,
    isPendingLanguage,
    isLanguageOpen,
    isFullScreen,
    isDataLoaded,
    isBlur,
    language,
    hideCloseButton,
    onCloseClick,
    overlayColor,
    overlayOpacity,
    isUpsellingActive,
    isEnterprise,
    phraseLoaded,
    setFileLoading,
    fileLoading,
    deviceType
  } = props
  const classNames = ['ta-widget']
  const formRef = useRef()
  if (hasOverwriteCss) classNames.push('overwrite')
  if (isFullScreen) classNames.push('fullscreen')
  if (isEnterprise) classNames.push('ta-widget-is-enterprise')
  if (isBlur) {
    classNames.push('ta-widget-blur')
    classNames.push('ta-widget__no-close')
  }
  if (hideCloseButton) classNames.push('ta-widget__no-close')
  if (isLanguageOpen) classNames.push('ta-widget__open-languages')
  if (deviceType) classNames.push(`type-${deviceType}`)
  const overlayStyles = {}
  if (overlayColor) overlayStyles.backgroundColor = overlayColor
  if (overlayOpacity) overlayStyles.opacity = overlayOpacity

  if (isUpsellingActive) classNames.push('with-upselling')
  const overlayClassnames = ['ta-widget-overlay']

  if (phraseLoaded) {
    overlayClassnames.push('ta-widget-overlay-disabled')
    classNames.push('ta-widget-disabled')
  }

  return (
    <>
      <div className={overlayClassnames.join(' ')} style={overlayStyles} />
      <div className={classNames.join(' ')}>
        {(hasOverwriteCss && isDataLoaded &&
          <EnterpriseLoader isActive={isPending || isPendingLanguage} />
        )}
        {(!hasOverwriteCss && isDataLoaded &&
          <Loader isActive={isPending || isPendingLanguage} />
        )}
        {!isPending && !isPendingLanguage && !hideCloseButton && (
          <Link className='ta-widget__close_button' onClick={onCloseClick}>
            <FontAwesome5 icon='times' type='light' />
          </Link>
        )}
        <div className='ta-widget__container'>
          {(isDataLoaded &&
            <>
              <HeaderWrapper />
              <div className='ta-widget__steps'>
                <NotFoundWrapper language={language} />
                <BookedOutWrapper language={language} />
                <ConflictWrapper language={language} />
                <LocationsWrapper language={language} />
                <ServicesWrapper
                  language={language}
                  fileLoading={fileLoading}
                  setFileLoading={setFileLoading}
                />
                <AvailabilityWrapper language={language} />
                <AuthWrapper language={language} />
                <CustomerFieldsWrapper
                  language={language}
                  setFileLoading={setFileLoading}
                  fileLoading={fileLoading}
                  formRef={formRef}
                />
                <ConfirmWrapper language={language} />
                <SuccessWrapper language={language} />
                <PaymentsWrapper language={language} />
                <PaymentStripeWrapper language={language} />
                <PaymentOnlinePaymentWrapper language={language} />
              </div>
              <FooterWrapper onSubmit={formRef?.current?.onSubmit} language={language} fileLoading={fileLoading} />
              <PopupWrapper language={language} />
              <EnterpriseLogoWrapper language={language} />
              <ProgressBarWrapper />
              <DebugOptionsWrapper />
              <CookieConsentMessageWrapper language={language} />
            </>
          )}
          {(!isDataLoaded &&
            <LoadingPlaceholder />
          )}
        </div>
        <UpSellingWrapper language={language} />
      </div>
    </>
  )
}

App.propTypes = {
  isPending: PropTypes.bool,
  isPendingLanguage: PropTypes.bool,
  isLanguageOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isDataLoaded: PropTypes.bool,
  isBlur: PropTypes.bool,
  language: PropTypes.string,
  phraseLoaded: PropTypes.bool
}

export default App
