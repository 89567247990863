import React, { useState } from 'react'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { Error } from '../../../Common'
import { handlers } from '../../../Store'
import { q } from '../../../Store/API'

import './PaymentsPayPalButton.css'

const paypalButtonStyles = {
  layout: 'horizontal', // 'vertical',
  color: 'white',
  shape: 'rect',
  label: 'paypal',
  height: 55
}

const PaymentsPayPalButton = ({
  eventId,
  companyId,
  region,
  secret
}) => {
  const [error, setError] = useState(null)

  return (
    <div className='ta-paypal-button'>
      <PayPalButtons
        style={paypalButtonStyles}
        fundingSource='paypal'
        createOrder={async (data, actions) => {
          const orderId = await q('createOnlineReservationPaypalOrder', {
            companyId,
            region,
            eventId,
            secret
          })
          if (orderId) return orderId
        }}
        onError={error => {
          console.error(error)
          setError({
            value: 'errors.paypal.paymentFailed',
            replace: [{ key: 'PAYPAL_ERROR', value: error.message }]
          })
        }}
        onCancel={async (data, actions) => {
          // if need to do something if user closes popup, without paying
        }}
        onApprove={async (data) => {
          const { orderID } = data || {}
          if (orderID) {
            handlers.paypalAuthorizationIdSave(orderID)
            handlers.bookingSave()
          }
        }}
      />
      {error && <Error error={error} />}
    </div>
  )
}

export default PaymentsPayPalButton
