import { EMAIL_VALIDATION_REGEX, DEFAULT_LOCALE_COUNTRY_CODE, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../../Settings'
import { removeSpaces, translateServerCode, validator } from '../../../Utils'

// Login

export const loginFormInitialValues = () => {
  return {
    // remember: {
    //   value: true
    // },
    email: {
      value: ''
    },
    password: {
      value: '',
      type: 'password'
    }
  }
}

export const loginSaveTransform = (form = {}) => {
  return {
    email: (form.email && form.email.value) || '',
    password: (form.password && form.password.value) || ''
    // remember: form.remember && form.remember.value
  }
}

export const loginFormValidate = (fields) => {
  const errors = []
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)
  let hasError = false

  // Email
  if (fields.email.length === 0) {
    errors.push({ key: 'emailOrPassword', value: 'errors.email.required' })
    hasError = true
  }

  if (fields.email.length > 0 && !emailRegex.test(fields.email)) {
    errors.push({ key: 'emailOrPassword', value: 'errors.email.invalid' })
    hasError = true
  }

  // Password
  if (!hasError && fields.password.length === 0) {
    errors.push({ key: 'emailOrPassword', value: 'errors.password.required' })
  }

  if (!hasError && fields.password.length > 0 && fields.password.length < 6) {
    errors.push({ key: 'emailOrPassword', value: 'errors.password.tooShort', replace: [{ key: 'MIN', value: 8 }] })
  }

  return errors
}

export const loginServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'emailOrPassword', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'emailOrPassword', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// Forgotten

export const forgottenFormInitialValues = () => {
  return {
    email: {
      value: ''
    }
  }
}

export const forgottenFormValidate = booker => {
  if (!booker) return
  const inputLength = 64
  const rules = [{
    'email.value': ['required', `max:${inputLength}`, 'email']
  }]
  const messages = {
    'email': {
      'email': 'errors.email.invalid',
      'required': 'errors.email.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.invalidMinLength'
    }
  }
  const replaces = {
    'email': {
      'max': { key: 'MAX', value: inputLength }
    }
  }
  const errors = validator(booker, rules, messages, replaces)

  return errors.length && errors
}

export const forgottenFormSaveTransform = booker => {
  return {
    authScope: 'BOOKER',
    email: booker.email.value
  }
}

export const forgottenFormServerErrorsTransform = (err) => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

// Enforce Password Change

export const enforcePasswordChangeFormInitialValues = () => {
  return {
    password: {
      value: '',
      type: 'password'
    },
    repeatPassword: {
      value: '',
      type: 'password'
    }
  }
}

export const enforcePasswordChangeFormValidate = (booker) => {
  if (!booker) return
  let { password, repeatPassword } = booker || {}
  password = password || {}
  repeatPassword = repeatPassword || {}
  const { value: passwordValue } = password || {}
  const { value: repeatPasswordValue } = repeatPassword || {}

  const passwordMinLength = PASSWORD_MIN_LENGTH
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [{
    'password.value': ['required', `max:${passwordMaxLength}`, `min:${passwordMinLength}`],
    'repeatPassword.value': ['required', `max:${passwordMaxLength}`, `min:${passwordMinLength}`]
  }]
  const messages = {
    'password': {
      'required': 'errors.password.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.password.weak'
    },
    'repeatPassword': {
      'required': 'errors.password.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.password.weak'
    }
  }
  const replaces = {
    'password': {
      'max': { key: 'MAX', value: passwordMaxLength },
      'min': { key: 'MIN', value: passwordMinLength }
    },
    'repeatPassword': {
      'max': { key: 'MAX', value: passwordMaxLength },
      'min': { key: 'MIN', value: passwordMinLength }
    }
  }
  const errors = validator(booker, rules, messages, replaces)

  if (passwordValue !== repeatPasswordValue && passwordValue.length >= passwordMinLength) {
    errors.push({ key: 'globalErrors', value: 'errors.password.notMatching' })
  }

  return errors.length && errors
}

export const enforcePasswordChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordWeak') {
    errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

export const enforcePasswordChangeFormSaveTransform = booker => {
  const { password, repeatPassword } = booker || {}
  const { value: passwordValue } = password || {}
  const { value: repeatPasswordValue } = repeatPassword || {}
  return {
    userData: {
      oldPassword: passwordValue,
      password: repeatPasswordValue
    }
  }
}

// Registration

export const registerFormTransform = (data = {}) => {
  const result = {
    name: {
      value: ''
    },
    surname: {
      value: ''
    },
    phone: {
      phone: '',
      phoneCountry: ''
    },
    email: {
      value: data.email || ''
    },
    password: {
      value: data.password || '',
      type: 'password'
    },
    terms: {
      value: false
    },
    companyTerms: {
      value: false
    }
  }
  return result
}

export const registerFormFacebookTransform = (data = {}) => {
  const name = (data.name && data.name.split(' ')) || []
  const firstName = name[0] || ''
  const lastName = name[1] || ''
  const result = {
    name: {
      value: firstName || ''
    },
    surname: {
      value: lastName || ''
    },
    phone: {
      phone: data.phone || '',
      phoneCountry: data.phoneCountry || ''
    },
    email: {
      value: data.email || ''
    },
    terms: {
      value: false
    },
    facebookToken: data.accessToken || ''
  }
  return result
}

export const registerFormValidate = (booker, company) => {
  if (!booker) return
  const phoneLength = 32
  const inputLength = 64
  const rules = [{
    'name.value': ['required', `max:${inputLength}`]
  }, {
    'surname.value': ['required', `max:${inputLength}`]
  }, {
    'password.value': ['required']
  }, {
    'email.value': ['required', `max:${inputLength}`, 'email']
  }, {
    'phone.phone': ['required', `max:${phoneLength}`, 'phone']
  }, {
    'terms.value': ['required']
  }]

  // If the company has custom terms & conditions or privacy link we must validate it
  if (company && company.settings && (company.settings.customPrivacyPolicyUrl || company.settings.customTermsAndConditionsUrl)) {
    rules.push({
      'companyTerms.value': ['required']
    })
  }

  const messages = {
    'name': {
      'max': 'errors.invalidMaxLength',
      'required': 'errors.required'
    },
    'surname': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'phone': {
      'required': 'errors.phone.required',
      'phone': 'errors.phone.invalid',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.invalidMinLength'
    },
    'email': {
      'email': 'errors.email.invalid',
      'required': 'errors.email.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.invalidMinLength'
    },
    'password': {
      'required': 'errors.required'
    },
    'terms': {
      'required': 'errors.terms.notChecked'
    }
  }
  const replaces = {
    'email': {
      'max': { key: 'MAX', value: inputLength }
    },
    'name': {
      'max': { key: 'MAX', value: inputLength },
      'requiredIf': { key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }
    },
    'phone': {
      'max': { key: 'MAX', value: phoneLength }
    }
  }
  const errors = validator(booker, rules, messages, replaces)

  return errors.length && errors
}

export const registerFormFacebookValidate = booker => {
  if (!booker) return

  const phoneLength = 32
  const inputLength = 64
  const rules = [{
    'name.value': ['required', `max:${inputLength}`]
  }, {
    'surname.value': ['required', `max:${inputLength}`]
  }, {
    'email.value': ['required', `max:${inputLength}`, 'email']
  }, {
    'phone.phone': ['required', `max:${phoneLength}`, 'phone']
  }, {
    'terms.value': ['required']
  }]

  const messages = {
    'name': {
      'max': 'errors.invalidMaxLength',
      'required': 'errors.required'
    },
    'surname': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'phone': {
      'required': 'errors.phone.required',
      'phone': 'errors.phone.invalid',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.invalidMinLength'
    },
    'email': {
      'email': 'errors.email.invalid',
      'required': 'errors.email.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.invalidMinLength'
    },
    'terms': {
      'required': 'errors.terms.notChecked'
    }
  }
  const replaces = {
    'email': {
      'max': { key: 'MAX', value: inputLength }
    },
    'name': {
      'max': { key: 'MAX', value: inputLength },
      'requiredIf': { key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }
    },
    'phone': {
      'max': { key: 'MAX', value: phoneLength }
    }
  }
  const errors = validator(booker, rules, messages, replaces)

  return errors.length && errors
}

export const registerFormSaveTransform = (booker, companyId) => {
  return {
    email: booker.email.value,
    password: booker.password.value,
    companyId: companyId,
    registrationData: {
      firstName: booker.name.value,
      lastName: booker.surname.value,
      phone: ((booker.phone && booker.phone.phone) && {
        number: removeSpaces(booker.phone.phone),
        country: booker.phone.phoneCountry
      }) || null,
      locale: booker.locale || 'en-gb'
    }
  }
}

export const registerFormFacebookSaveTransform = (booker, companyId) => {
  return {
    facebookToken: booker.facebookToken,
    companyId: companyId,
    registrationData: {
      firstName: booker.name.value,
      lastName: booker.surname.value,
      phone: (booker.phone && booker.phone.phone && {
        number: removeSpaces(booker.phone.phone),
        country: booker.phone.phoneCountry
      }) || null,
      email: booker.email.value,
      timezone: booker.timezone,
      locale: booker.locale
    }
    // longSession: true
  }
}

export const registerFormServerErrorsTransform = (err) => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code), replace: err.replace || [] })
  }
  return errors
}

// Guest

export const guestFormInitialValues = () => {
  const result = {
    firstName: {
      value: ''
    },
    lastName: {
      value: ''
    },
    phone: {
      phone: '',
      phoneCountry: DEFAULT_LOCALE_COUNTRY_CODE
    },
    email: {
      value: ''
    },
    terms: {
      value: false
    }
  }
  return result
}

//
//
//
//
//
//
//
//
//

// export const authPopulateTransform = (booker) => {
//   const result = {
//     name: (booker.account && booker.account.booker && booker.account.booker.firstName) || '',
//     surname: (booker.account && booker.account.booker && booker.account.booker.lastName) || '',
//     phone: {
//       phone: (booker.account && booker.account.booker && booker.account.booker.phone && booker.account.booker.phone.number) || '',
//       phoneCountry: (booker.account && booker.account.booker && booker.account.booker.phone && booker.account.booker.phone.country) || DEFAULT_LOCALE_COUNTRY_CODE
//     },
//     email: (booker.account && booker.account.email) || '',
//     timezone: (booker.account && booker.account.booker && booker.account.booker.timezone) || ''
//   }
//   return result
// }

// export const loginFormTransform = () => {
//   const result = {
//     email: {
//       value: ''
//     },
//     password: {
//       value: ''
//     }
//   }
//   return result
// }

// export const loginFormValidate = booker => {
//   if (!booker) return
//   const inputLength = 64
//   const rules = [{
//     'email.value': ['required', `max:${inputLength}`, 'email']
//   }, {
//     'password.value': ['required']
//   }]
//   const messages = {
//     'email': {
//       'email': 'errors.email.invalid',
//       'required': 'errors.email.required',
//       'max': 'errors.invalidMaxLength',
//       'min': 'errors.invalidMinLength'
//     },
//     'password': {
//       'required': 'errors.required'
//     }
//   }
//   const replaces = {
//     'email': {
//       'max': { key: 'MAX', value: inputLength }
//     }
//   }
//   const errors = validator(booker, rules, messages, replaces)

//   return errors.length && errors
// }

// export const loginFormSaveTransform = booker => {
//   return {
//     email: booker.email.value,
//     password: booker.password.value
//   }
// }

// export const loginFormServerErrorsTransform = (err) => {
//   const errors = []
//   if (err && err.code) {
//     if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
//   }
//   return errors
// }

// export const guestBookingFormTransform = () => {
//   const result = {
//     name: {
//       value: ''
//     },
//     surname: {
//       value: ''
//     },
//     phone: {
//       phone: '',
//       phoneCountry: DEFAULT_LOCALE_COUNTRY_CODE
//     },
//     email: {
//       value: ''
//     },
//     terms: {
//       value: false
//     }
//   }
//   return result
// }

// export const guestBookingFormValidate = booker => {
//   if (!booker) return
//   const phoneLength = 32
//   const inputLength = 64
//   const rules = [{
//     'name.value': ['required', `max:${inputLength}`]
//   }, {
//     'surname.value': ['required', `max:${inputLength}`]
//   }, {
//     'email.value': ['required', `max:${inputLength}`, 'email']
//   }, {
//     'phone.phone': ['required', `max:${phoneLength}`, 'phone']
//   }, {
//     'terms.value': ['required']
//   }]
//   const messages = {
//     'name': {
//       'max': 'errors.invalidMaxLength',
//       'required': 'errors.required'
//     },
//     'surname': {
//       'required': 'errors.required',
//       'max': 'errors.invalidMaxLength'
//     },
//     'phone': {
//       'required': 'errors.phone.required',
//       'phone': 'errors.phone.invalid',
//       'max': 'errors.invalidMaxLength',
//       'min': 'errors.invalidMinLength'
//     },
//     'email': {
//       'email': 'errors.email.invalid',
//       'required': 'errors.email.required',
//       'max': 'errors.invalidMaxLength',
//       'min': 'errors.invalidMinLength'
//     },
//     'terms': {
//       'required': 'errors.required'
//     }
//   }
//   const replaces = {
//     'email': {
//       'max': { key: 'MAX', value: inputLength }
//     },
//     'name': {
//       'max': { key: 'MAX', value: inputLength },
//       'requiredIf': { key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }
//     },
//     'phone': {
//       'max': { key: 'MAX', value: phoneLength }
//     }
//   }
//   const errors = validator(booker, rules, messages, replaces)

//   return errors.length && errors
// }

// Customer fields transform
export const customerFieldsTransform = (fields) => {
  try {
    fields.forEach(field => {
      if (field.type) {
        switch (field.type) {
          case 'PHONE':
          case 'ADDRESS':
            field.value = JSON.parse(field.value)
            break

          default:
        }
      }
    })
  } catch (e) {}
  return fields
}
