import React from 'react'
import { connect } from '../../Store'
import { BookingInfo, BookingInfoMrSpex } from '../../Beauties'
import { getDiscountedPrice } from '../../Utils'

const BookingInfoWrapper = props => {
  const {
    routeName,
    appMode,
    companies,
    selectedCompanyId,
    services,
    selectedServiceId,
    courses,
    selectedCourseId,
    resources,
    selectedResourceId,
    selectedDay,
    selectedMinutes,
    selectedOfferId,
    customerFieldsOnService,
    rescheduledEvent,
    offers,
    attributes,
    coursesList,
    selectedEventId,
    extraParticipants,
    nextPage,
    stripePaymentIntentExpireAt,
    countries
  } = props
  let info = { selectedDay, selectedMinutes, stripePaymentIntentExpireAt }
  let cardDetails = false
  const {
    title: rescheduledTitle = '',
    duration: rescheduledDuration,
    durationsPatternCalc: rescheduledDurationsPatternCalc,
    combination: rescheduledCombination
  } = rescheduledEvent || {}
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const { settings = {}, addOns = [], stripeFeePercentage, locale = 'en-gb' } = company
  const country = countries.find(({ code }) => code.toLowerCase() === locale.split('-')[1])
  const { hasStripeFeesNotAllowed } = country || {}
  const selectedService = services.find(item => item.id === selectedServiceId)
  const selectedCourse = courses.find(item => item.id === selectedCourseId)
  const selectedEvent = coursesList.find(item => item.id === selectedEventId)
  const selectedItem = selectedService || selectedEvent || selectedCourse
  const selectedResource = resources.find(item => item.id === selectedResourceId)
  const customerFieldsWithValues = Object.entries(customerFieldsOnService).reduce((acc, item) => {
    if (item[0].indexOf('customerField') !== -1 &&
      (
        (item[1].type === 'ADDRESS' && item[1].value) ||
        (item[1].type === 'PHONE' && item[1].phone && item[1].phone !== '') ||
        (item[1].value && item[1].value !== '')
      )
    ) acc.push(item[1])
    return acc
  }, [])
  const { isEnterprise = false } = attributes
  let additionalParticipantsCount = parseInt(extraParticipants, 10)
  if (isNaN(additionalParticipantsCount)) additionalParticipantsCount = 0

  // If selected item is course we must get right price for it
  if (selectedCourse && selectedCourse.price) {
    selectedItem.price = selectedCourse.price
  }

  // Service
  if (selectedItem) {
    const offer = selectedOfferId ? offers.find(item => item.id === selectedOfferId) : null

    info.service = { name: selectedItem.name || selectedItem.title }
    if (!settings.hideServiceDuration) {
      info.service.duration =
        rescheduledDurationsPatternCalc ||
        rescheduledDuration ||
        selectedItem.durationsPatternCalc ||
        selectedItem.duration
      if (rescheduledCombination && rescheduledCombination.events && rescheduledCombination.events.length) {
        info.service.duration = rescheduledCombination.events.reduce((duration, item) => duration + (item || {}).duration, 0)
      }
    }
    info.service.price = selectedItem.price
    if (offer && selectedItem.price) info.service.discountedPrice = getDiscountedPrice(selectedItem.price, offer.discount)
    // Online payment routes + confirm(if online payment option is chosen. nextPage is defined only if online payment is chosen)
    if (selectedItem.hasOnlinePayment &&
        (['paymentOnlinePayment', 'paymentStripe'].includes(routeName) || (routeName === 'confirm' && !!nextPage))
    ) {
      const initialPrice = selectedItem && (selectedItem.price)
      info.service.price = initialPrice
      if (routeName === 'paymentStripe' && !hasStripeFeesNotAllowed && stripeFeePercentage > 0) {
        info.service.taxProcent = stripeFeePercentage || 0
        info.service.taxPrice = initialPrice - getDiscountedPrice(initialPrice, info.service.taxProcent)
        info.service.price = initialPrice + info.service.taxPrice
        if (offer) {
          // service has discount
          const discountedPrice = getDiscountedPrice(initialPrice, offer.discount)
          info.service.taxPrice = discountedPrice - getDiscountedPrice(discountedPrice, info.service.taxProcent)
          info.service.price = initialPrice + info.service.taxPrice
          info.service.discountedPrice = discountedPrice + info.service.taxPrice
        }
      }
      if (additionalParticipantsCount > 0) {
        // if course booking with extra participants
        const totalPrice = initialPrice * (additionalParticipantsCount + 1)
        info.service.taxPrice = totalPrice - getDiscountedPrice(totalPrice, info.service.taxProcent)
        const totalPriceWithTax = (initialPrice * (additionalParticipantsCount + 1)) + info.service.taxPrice
        info.service.price = totalPriceWithTax / (additionalParticipantsCount + 1) // because of the way calculations are made in beauty. IMO everything should be calculated here to avoid stupid double calculations like this!
      }
    }
  } else if (appMode === 'RESCHEDULE' && rescheduledTitle !== '') {
    // If we are in rescheduling mode and the booking is with title
    info.service = { name: rescheduledTitle }
  }
  // Resource
  if (selectedResource && !settings.hideResourcesSection) {
    info.resource = { name: selectedResource.name }
  }
  if (selectedCourse) {
    info.service.extraPersonsPerParticipant = additionalParticipantsCount
    info.service.courseId = selectedCourseId

    if (selectedCourse.resources && selectedCourse.resources.length > 0 && !settings.hideResourcesSection) {
      info.courseResources = selectedCourse.resources.reduce((acc, item) => { acc.push(item.name); return acc }, []).join(', ')
    }
  }
  // CustomerFields
  if (customerFieldsWithValues) {
    info.fields = customerFieldsWithValues
  }

  // TODO filter keys by step
  // Filters
  if (['auth', 'customerFields', 'payments', 'paymentCreditCard', 'paymentOnlinePayment', 'paymentStripe', 'confirm'].includes(routeName)) cardDetails = true
  if (['locations', 'services', 'conflict', 'success', 'notFound', 'bookedOut'].includes(routeName)) info = {}

  const isActive = Object.keys(info).length > 0

  return (
    <>
      {((!isEnterprise || !addOns || !addOns.includes('MRSPEX')) &&
        <BookingInfo
          info={info}
          isActive={isActive}
          routeName={routeName}
          cardDetails={cardDetails}
          hasTransactionFee={!!info?.service?.taxPrice}
        />
      )}
      {(isEnterprise && addOns && addOns.includes('MRSPEX') &&
        <BookingInfoMrSpex
          isActive={isActive}
          info={info}
          cardDetails={cardDetails}
        />
      )}
    </>
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  appMode: state.app.mode || 'NORMAL',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  services: state.services.list || [],
  selectedServiceId: state.services.selectedId || null,
  courses: state.courses.list || [],
  selectedCourseId: state.courses.selectedId || null,
  resources: state.resources.list || [],
  selectedResourceId: state.resources.selectedId || null,
  coursesList: state.slots.coursesList || [],
  selectedDay: state.slots.selectedDay || null,
  selectedMinutes: state.slots.selectedMinutes || 0,
  selectedEventId: state.slots.selectedEventId || null,
  selectedOfferId: state.slots.selectedOfferId || null,
  customerFieldsOnService: state.forms.customerFieldsOnService || {},
  rescheduledEvent: (state.booking && state.booking.rescheduledData) || {},
  offers: state.offers.list || [],
  attributes: state.attributes || {},
  extraParticipants: state.courses.extraParticipants || '',
  nextPage: state.pages.nextPage || null,
  stripePaymentIntentExpireAt: state.stripe.expireAt || null,
  countries: state.countries || []
})

export default connect(maps)(BookingInfoWrapper)
