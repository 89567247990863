import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CoursesExtraPersonsPerParticipantFormWrapper } from '../../Beasts'
import { CustomerFormField } from '../../Beauties'
import { t, DashedTitle, Form, Checkbox, SmallTitle } from '../../Common'
import { dangerousHTML } from '../../Utils'
import { INTERSPORT_ENTERPRISE_ID } from '../../Settings'
import { globalActions } from '../../Store'

import './CustomerFields.css'

const CustomerFields = props => {
  let {
    isActive,
    isPrevious,
    isGuest,
    company,
    customTermsAndConditionsUrl,
    customPrivacyPolicyUrl,
    filteredCustomerFields,
    timezones,
    enterpriseId,
    selectedEvent,
    onSubmit,
    setFileLoading,
    fileLoading,
    isBooker,
    shouldDisplayDisclaimer,
    disclaimerText,
    locale,
    formRef
  } = props
  company = company || {}
  // const containerRef = useTabFocusable(isActive)
  const ref = useRef()
  const classNames = ['ta-step', 'ta-customer-fields', 'has-button']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')
  let companyName = company.name || ''
  if (enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId)) companyName = 'INTERSPORT'
  // We must add additional validation for the custom terms & conditions. If we have MisterSpex partner we must
  // change the locale key because we must overwrite it for this partner
  let companyTermsAndPrivacyLocaleKey = 'checkbox.company_terms_and_privacy.label'
  if (customTermsAndConditionsUrl === 'MISTERSPEX_PARTNER_TERMS') companyTermsAndPrivacyLocaleKey = 'customerdata.preview.section.form.checkbox.company_terms_and_privacy_misterspex_partner.label'

  useEffect(() => {
    let interval
    let element
    interval = setInterval(() => {
      element = ref.current
      if (element && isActive) {
        globalActions.postMessage({
          action: 'timify:widget:customerfields:container',
          height: element.clientHeight
        }, '*')
      }
    }, 500)

    return () => clearInterval(interval)
  }, [ref, isActive])

  return (
    <div className={classNames.join(' ')}>
      <Form name='customerFieldsBeforeConfirmation' ref={formRef} onSubmit={onSubmit} >
        <div className='ta-customer-fields__container' ref={ref}>

          {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
            <SmallTitle label={t('customerdata.preview.header.title')} className='ta-enterprise__intersport-title' />
          )}

          <DashedTitle label={t('customerdata.preview.section.info.title')} className='ta-popup__main_title' />
          {filteredCustomerFields.map(item => (
            <CustomerFormField
              key={item.id}
              field={item}
              timezones={timezones}
              plan={company.plan}
              setFileLoading={setFileLoading}
              fileLoading={fileLoading}
              isBooker={isBooker}
            />
          ))}

          {(selectedEvent && selectedEvent.extraPersonsPerParticipant > 0 &&
            <CoursesExtraPersonsPerParticipantFormWrapper />
          )}

          {(!isGuest &&
            <div className='ta-auth-register__checkbox'>
              <Checkbox
                name='terms'
                label={dangerousHTML(t('checkbox.terms.label', [{
                  key: 'TERMS_CONDITIONS',
                  value: `<a href="https://www.timify.com/${locale}/pages/terms-of-use-smb/" target="_blank">${t('global.termsAndConditions')}</a>`
                }, {
                  key: 'PRIVACY_POLICY',
                  value: `<a href="https://www.timify.com/${locale}/legal/" target="_blank">${t('global.privacyPolicy')}</a>`
                }], undefined, true), false, true)}
              />
            </div>
          )}

          <div className='ta-auth-register__checkbox'>
            {(customTermsAndConditionsUrl && customPrivacyPolicyUrl &&
              <Checkbox
                name='companyTerms'
                label={dangerousHTML(t(companyTermsAndPrivacyLocaleKey, [{
                  key: 'COMPANY_NAME',
                  value: companyName
                }, {
                  key: 'TERMS_CONDITIONS',
                  value: `<a href="${customTermsAndConditionsUrl}" target="_blank">${t('global.termsAndConditions')}</a>`
                }, {
                  key: 'PRIVACY_POLICY',
                  value: `<a href="${customPrivacyPolicyUrl}" target="_blank">${t('global.privacyPolicy')}</a>`
                }], undefined, true), false, true)}
              />
            )}

            {(customTermsAndConditionsUrl && !customPrivacyPolicyUrl &&
              <Checkbox
                name='companyTerms'
                label={dangerousHTML(t('checkbox.company_terms.label', [{
                  key: 'COMPANY_NAME',
                  value: companyName
                }, {
                  key: 'TERMS_CONDITIONS',
                  value: `<a href="${customTermsAndConditionsUrl}" target="_blank">${t('global.termsAndConditions')}</a>`
                }], undefined, true), false, true)}
              />
            )}

            {(!customTermsAndConditionsUrl && customPrivacyPolicyUrl &&
              <Checkbox
                name='companyTerms'
                label={dangerousHTML(t('checkbox.company_privacy.label', [{
                  key: 'COMPANY_NAME',
                  value: companyName
                }, {
                  key: 'PRIVACY_POLICY',
                  value: `<a href="${customPrivacyPolicyUrl}" target="_blank">${t('global.privacyPolicy')}</a>`
                }], undefined, true), false, true)}
              />
            )}
          </div>
          <button type='submit' className='ta-cta ta-cta-primary ta-customer-fields__hidden' disabled={fileLoading}>
            {t('customerdata.preview.section.form.button.label')}
          </button>
          {(shouldDisplayDisclaimer &&
            <div className='ta-disclaimer'>
              {dangerousHTML(disclaimerText, false, true)}
            </div>
          )}
        </div>
      </Form>
    </div>
  )
}

CustomerFields.propTypes = {
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isGuest: PropTypes.bool
}

export default CustomerFields
