import { SUPPORTED_LOCALES } from '../../../Settings'

export const attributesTransform = attributes => {
  const result = { ...attributes }

  if (attributes.resourceExternalIds) result.resourceExternalIds = (attributes.resourceExternalIds && attributes.resourceExternalIds.split(',')) || []

  if (attributes.serviceExternalIds) result.serviceExternalIds = (attributes.serviceExternalIds && attributes.serviceExternalIds.split(',')) || []
  if (attributes.courseExternalIds) result.courseExternalIds = (attributes.courseExternalIds && attributes.courseExternalIds.split(',')) || []

  // Variables for pre-selected service. First is for the new one.. second to support old widget integration
  if (attributes.serviceId) {
    result.serviceId = (attributes.serviceId || '').split(',')
  } else if (attributes.preFillServiceId) {
    result.serviceId = (attributes.preFillServiceId || '').split(',')
  }

  // Variables for pre-selected service exteernal ids same as one in API filter param
  if (attributes.filterByServiceExternalIds) result.filterByServiceExternalIds = (attributes.filterByServiceExternalIds || '').split(',')

  // Variables for pre-selected service ids same as one in API filter param
  if (attributes.filterByServiceIds) result.filterByServiceIds = (attributes.filterByServiceIds || '').split(',')

  // Variables for pre-selected course. First is for the new one.. second to support old widget integration
  if (attributes.courseId) {
    result.courseId = (attributes.courseId || '').split(',')
  } else if (attributes.preFillGroupServiceId) {
    result.courseId = (attributes.preFillGroupServiceId || '').split(',')
  }

  // Show only services which are set by this parameter
  if (attributes.showServices) result.showServices = (attributes.showServices || '').split(',')

  // Hide services which are set by this parameter
  if (attributes.hideServices) result.hideServices = (attributes.hideServices || '').split(',')

  // Show only services which are set by this parameter
  if (attributes.showServicesExternalIds) result.showServicesExternalIds = (attributes.showServicesExternalIds || '').split(',')

  // Hide services which are set by this parameter
  if (attributes.hideServicesExternalIds) result.hideServicesExternalIds = (attributes.hideServicesExternalIds || '').split(',')

  // Variables for pre-selected resource. First is for the new one.. second to support old widget integration
  if (attributes.resourceId) {
    if (Array.isArray(attributes.resourceId)) {
      result.resourceId = attributes.resourceId.find(res => res !== '')
    } else {
      result.resourceId = attributes.resourceId
    }
  } else if (attributes.preFillEmployeeId) {
    result.resourceId = attributes.preFillEmployeeId
  }

  // Variables to show only pre-selected resource. First is for the new one.. second to support old widget integration
  if (result.resourceId && (attributes.showSelectedResourcesOnly || attributes.preFillEmployeeOnly)) {
    result.showSelectedResourcesOnly = true
  } else {
    result.showSelectedResourcesOnly = false
  }

  // Variables to filter resources by Id or externalId
  if (attributes.filterResources) {
    result.filterResources = attributes.filterResources.split(',')
  } else if (attributes.filterResourcesbyExternalId) {
    result.filterResourcesbyExternalId = attributes.filterResourcesbyExternalId.split(',')
  }

  // Variables to filter resource categories by Id or externalId
  if (attributes.filterResourceCategories) {
    result.filterResourceCategories = attributes.filterResourceCategories.split(',')
  } else if (attributes.filterResourceCategoriesbyExternalId) {
    result.filterResourceCategoriesbyExternalId = attributes.filterResourceCategoriesbyExternalId.split(',')
  }

  // Variables to filter resource externalIds
  if (attributes.filterByResourceExternalIds) {
    result.filterByResourceExternalIds = attributes.filterByResourceExternalIds.split(',')
  }

  // Variables for pre-selected guest. First is for the new one.. second to support old widget integration
  if (attributes.guestFirstName) {
    result.guestFirstName = attributes.guestFirstName
  } else if (attributes.preFillGuestFirstName) {
    result.guestFirstName = attributes.preFillGuestFirstName
  }

  if (attributes.guestLastName) {
    result.guestLastName = attributes.guestLastName
  } else if (attributes.preFillGuestLastName) {
    result.guestLastName = attributes.preFillGuestLastName
  }

  if (attributes.guestPhone) {
    result.guestPhone = attributes.guestPhone
  } else if (attributes.preFillGuestPhone) {
    result.guestPhone = attributes.preFillGuestPhone
  }

  if (attributes.guestPhoneCountry) {
    result.guestPhoneCountry = attributes.guestPhoneCountry
  } else if (attributes.preFillGuestPhoneCountry) {
    result.guestPhoneCountry = attributes.preFillGuestPhoneCountry
  }

  if (attributes.guestEmail) {
    result.guestEmail = attributes.guestEmail
  } else if (attributes.preFillGuestEmail) {
    result.guestEmail = attributes.preFillGuestEmail
  }

  if (attributes.skipCustomerFieldsSection) {
    result.skipCustomerFieldsSection = true
  } else {
    result.skipCustomerFieldsSection = false
  }

  // Add variable to open guest booking form
  if (attributes.showGuestBookingForm) {
    result.showGuestBookingForm = true
  } else {
    result.showGuestBookingForm = false
  }

  // Validate locale parameter
  if (attributes.locale && SUPPORTED_LOCALES.includes(attributes.locale.toLowerCase())) {
    result.locale = attributes.locale.toLowerCase()
  } else {
    result.locale = null
  }

  // Validate in new tab parameter
  if (attributes.inNewTab) {
    result.inNewTab = true
  } else {
    result.inNewTab = false
  }

  // Validate date range parameter
  if (attributes.date && attributes.disableOtherDates) {
    result.dateRange = [attributes.date, attributes.date]
  } else if (attributes.dateRange) {
    const dateRange = attributes.dateRange.split(':')
    if (dateRange[0] && dateRange[1]) result.dateRange = dateRange
    else delete result.dateRange
  }

  // Validate new field for showResources
  if (attributes.showResources) {
    if (attributes.showResources === 'true') result.showResources = true
    else if (attributes.showResources === 'false') result.showResources = false
  }

  // Add variable to show timezone selector
  if (attributes.collapseSlots) {
    if (attributes.collapseSlots === 'true') result.collapseSlots = true
    else if (attributes.collapseSlots === 'false') result.collapseSlots = false
  }

  // Add variable to show timezone selector
  if (attributes.showTimezone) {
    if (attributes.showTimezone === 'true') result.showTimezone = true
    else if (attributes.showTimezone === 'false') result.showTimezone = false
  }

  // Add variable to show timezone selector
  if (attributes.disableOtherDates) {
    if (attributes.disableOtherDates === 'true') result.disableOtherDates = true
    else if (attributes.disableOtherDates === 'false') result.disableOtherDates = false
  }

  // Add variable to show company name in the header instead of default title
  if (attributes.showCompanyNameInHeader) {
    result.showCompanyNameInHeader = true
  } else {
    result.showCompanyNameInHeader = false
  }

  // Validate parameter to show horizontal datepicker
  if (attributes.horizontalCalendarLayout) {
    if (attributes.horizontalCalendarLayout === 'true') result.horizontalCalendarLayout = true
    else if (attributes.horizontalCalendarLayout === 'false') result.horizontalCalendarLayout = false
  }

  // Validate parameter to show horizontal month selector
  if (attributes.horizontalMonthSelector) {
    if (attributes.horizontalMonthSelector === 'true') result.horizontalMonthSelector = true
    else if (attributes.horizontalMonthSelector === 'false') result.horizontalMonthSelector = false
  }

  // Validate parameter to overwrite company settings
  if (attributes.displayWeeklyView) {
    if (attributes.displayWeeklyView === 'true') result.displayWeeklyView = true
    else if (attributes.displayWeeklyView === 'false') result.displayWeeklyView = false
  }

  // Hide prefilled customer fields
  if (attributes.hideFilledFields) {
    if (attributes.hideFilledFields === 'true') result.hideFilledFields = true
    else if (attributes.hideFilledFields === 'false') result.hideFilledFields = false
  }

  return result
}
