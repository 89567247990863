import fetch from 'isomorphic-fetch'
import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  APP_VERSION,
  PATH_TO_S3_LOCALE
} from '../Settings'
import { flattenMessages } from '../Utils'
import { store } from '../Store'

export const translations = {}

export const languageChange = async (locale = DEFAULT_LOCALE, cb) => {
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)
  const v = isStaging
    ? (new Date()).getTime()
    : APP_VERSION
  locale = locale && locale.toLowerCase()
  if (SUPPORTED_LOCALES.indexOf(locale) === -1) locale = DEFAULT_LOCALE
  if (!translations[locale]) {
    const defaultUrl = isStaging && PATH_TO_S3_LOCALE
      ? `${PATH_TO_S3_LOCALE}/en-gb.json?v=${v}`
      : `/locales/en-gb.json?v=${v}`
    const url = isStaging && PATH_TO_S3_LOCALE
      ? `${PATH_TO_S3_LOCALE}/${locale}.json?v=${v}`
      : `/locales/${locale}.json?v=${v}`
    const defaultTranslations = await fetch(defaultUrl)
      .then(response => response.json())
      .catch(error => console.warn('Translations error 1: ', error))
    const data = locale === 'en-gb'
      ? defaultTranslations
      : await fetch(url)
        .then(response => response.json())
        .catch(error => console.warn('Translations error 2: ', error))

    const state = store.getState()
    // Load specific locale for an enterprise account
    const enterpriseId = state.enterprise.id || null
    let enterpriseData = null
    if (enterpriseId) {
      enterpriseData = await fetch(`/locales/enterprises/${enterpriseId}.json?v=${v}`)
        .then(response => response.json())
        .catch(error => console.warn('Translations error 3: ', error))
    }
    // Load text customisations
    const customisations = state.customisations || {}
    const { payload } = customisations || {}
    const { translations: customisationTranslations } = payload || {}
    let { _all_: allLocales } = customisationTranslations || {}
    allLocales = allLocales || []
    const customisationTranslationsObject = allLocales.reduce((acc, item) => {
      acc[item.key] = item.value
      return acc
    }, {})
    translations[locale] = {
      ...flattenMessages(defaultTranslations || {}),
      ...flattenMessages(data || {}),
      ...flattenMessages(enterpriseData || {}),
      ...flattenMessages(customisationTranslationsObject || {})
    }
    cb(locale)
  } else cb(locale)
}
