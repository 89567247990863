import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CustomerFieldPreview } from '../../Beauties'
import {
  t,
  format,
  FontAwesome5,
  DashedTitle,
  LinedTitle,
  Price,
  Message,
  SmallTitle,
  getCustomDateFormat,
  getCustomTimeFormat
} from '../../Common'
import {
  convertMinutesToHours,
  dangerousHTML
} from '../../Utils'
import { INTERSPORT_ENTERPRISE_ID } from '../../Settings'
import { globalActions } from '../../Store'

import './Confirm.css'

const Confirm = props => {
  const {
    info,
    appMode,
    isActive,
    enterpriseId,
    disclaimerText,
    hasStripeActive,
    hasNotification,
    selectedTimezone,
    rescheduledEvent,
    hasStripeConnected,
    onNotificationClose,
    resourceExternalIds,
    hasStripeFeesNotAllowed,
    shouldDisplayDisclaimer,
    customerFieldsWithValues,
    onCourseParticipantsUpdate
  } = props
  const ref = useRef()
  const { service, resource, courseResources, slot } = info
  const customTimeFormat = getCustomTimeFormat()
  let slotMinutes = convertMinutesToHours(slot && slot.minutes)
  if (customTimeFormat) slotMinutes = format(slotMinutes, customTimeFormat, { isUTC: true, format: 'LT' })
  const classNames = ['ta-step', 'ta-confirm']
  if (isActive) classNames.push('active')

  useEffect(() => {
    let interval
    let element
    interval = setInterval(() => {
      element = ref.current
      if (element && isActive) {
        globalActions.postMessage({
          action: 'timify:widget:confirmation:container',
          height: element.clientHeight
        }, '*')
      }
    }, 500)

    return () => clearInterval(interval)
  }, [ref, isActive])

  return (
    <div className={classNames.join(' ')}>
      {(isActive && (
        <div className='ta-confirm__container' ref={ref}>

          {(appMode === 'RESCHEDULE' && rescheduledEvent &&
            <Message
              show={hasNotification}
              type='warning'
              onClick={onNotificationClose}
              label={t('confirm.preview.section.reschedule.title')}
              className='ta-confirm__notification'
            >
              {(rescheduledEvent.resourcesNames
                ? t('confirm.preview.section.reschedule.message', [{
                  key: 'DATE',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomDateFormat() || 'long')
                    : ''
                }, {
                  key: 'TIME',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomTimeFormat() || 'time')
                    : ''
                }, {
                  key: 'RESOURCE_NAME',
                  value: (rescheduledEvent.resourcesNames && rescheduledEvent.resourcesNames.join(', ')) || ''
                }])
                : t('confirm.preview.section.reschedule.withoutResource.message', [{
                  key: 'DATE',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomDateFormat() || 'long')
                    : ''
                }, {
                  key: 'TIME',
                  value: rescheduledEvent.appointmentDate
                    ? format(rescheduledEvent.appointmentDate, getCustomTimeFormat() || 'time')
                    : ''
                }])
              )}
            </Message>
          )}
          {(service && service.courseId && service.maxExtraPersonsPerParticipant > 0 &&
            <Message
              show
              type='warning'
              label={t('groups.extraParticipants.bookingInfo.message', [{ key: 'COUNT', value: service.extraPersonsPerParticipant + 1 }])}
              className='ta-confirm__notification'
            >
              <span
                className='ta-confirm__extra-persons-button'
                onClick={onCourseParticipantsUpdate}
              >
                {t('groups.extraParticipants.bookingInfo.link.label')}
              </span>
            </Message>
          )}

          {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
            <SmallTitle label={t('confirm.preview.header.title')} className='ta-enterprise__intersport-title' />
          )}

          {appMode !== 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title')} className='ta-popup__main_title' />}
          {appMode === 'RESCHEDULE' && <DashedTitle label={t('confirm.preview.section.info.title_reschedule')} className='ta-popup__main_title' />}

          {(enterpriseId && ['5c66f5673147e64eeead19c7', '5c813242d1b1b55743bdedc2'].includes(enterpriseId) &&
            <DashedTitle label={t('confirm.preview.section.bookingInfo.title')} className='ta-availability__booking__title' />
          )}

          <div className='ta-confirm__rows'>

            {(service &&
              <div className='ta-confirm__row service'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.service.label')}
                  </div>
                )}
                <div className='ta-confirm__row__service-data'>
                  <div className='ta-confirm__row__service-data__name'>
                    <FontAwesome5 icon='list' type='regular' className='ta-confirm__icon' />
                    <span>{service.name}</span>
                  </div>

                  {(service.duration &&
                    <div className='ta-confirm__value'>
                      <span>{service.duration} {t('services.list.section.category.service.min.label')}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {(slot &&
              <div>
                {(slot.day &&
                  <div className='ta-confirm__row'>

                    {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.day.label')}
                      </div>
                    )}

                    <FontAwesome5 icon='calendar-alt' type='solid' className='ta-confirm__icon' />
                    {format(slot.day, getCustomDateFormat() || 'longWithLongWeekDay', { isUTC: true, format: 'YYYY-MM-DD' })}
                  </div>
                )}

                {(slot.minutes &&
                  <div className='ta-confirm__row'>

                    {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                      <div className='ta-confirm__label'>
                        {t('confirm.preview.section.time.label')}
                      </div>
                    )}

                    <FontAwesome5 icon='clock' type='solid' className='ta-confirm__icon' />
                    {t('confirm.preview.section.info.minutes.label', [{
                      key: 'TIME',
                      value: slotMinutes
                    }])}

                    {(selectedTimezone &&
                      <span className='ta-confirm__timezone'>{`(${selectedTimezone.label})`}</span>
                    )}
                  </div>
                )}
              </div>
            )}

            {(resource &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.resource.label')}
                  </div>
                )}

                <FontAwesome5 icon='user' type='solid' className='ta-confirm__icon' />
                {t('confirm.preview.section.info.resources.by.label')} {resource.name}
              </div>
            )}

            {(courseResources &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.resource.label')}
                  </div>
                )}

                <FontAwesome5 icon='user' type='solid' className='ta-confirm__icon' />
                {dangerousHTML(t('groups.with', [{ key: 'RESOURCES', value: courseResources }]))}
              </div>
            )}

            {(service && service.courseId &&
              <div className='ta-confirm__row'>

                {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.participants.label')}
                  </div>
                )}

                <FontAwesome5 icon='users' type='solid' className='ta-confirm__icon' />
                {((!service.extraPersonsPerParticipant || service.extraPersonsPerParticipant < 1) &&
                  <>
                    {t('confirm.preview.section.participants.single.label')}
                    <span className='ta-services__discounted__price ta-confirm__icon__price-calculated'>({t('confirm.preview.section.participants.you.label')})</span>
                  </>
                )}
                {(!(!service.extraPersonsPerParticipant || service.extraPersonsPerParticipant < 1) &&
                  <>
                    {t('confirm.preview.section.participants.multiple.label', [{ key: 'PARTICIPANTS', value: service.extraPersonsPerParticipant + 1 }])}
                    <span className='ta-services__discounted__price ta-confirm__icon__price-calculated'>({t('confirm.preview.section.participants.you.label')} + {service.extraPersonsPerParticipant})</span>
                  </>
                )}
              </div>
            )}

            {enterpriseId && ['5c66f5673147e64eeead19c7', '5c813242d1b1b55743bdedc2'].includes(enterpriseId) && resourceExternalIds && resourceExternalIds.length > 0 &&
              resourceExternalIds.map((item, index) => (
                <div key={`external-id-${index}`} className='ta-confirm__row'>
                  {item}
                </div>
              ))}

            {(service && service.price && service.price > 0 &&
              <div className='ta-confirm__row price'>
                <div className='ta-confirm__row__price-data'>

                  {enterpriseId && INTERSPORT_ENTERPRISE_ID.includes(enterpriseId) && (
                    <div className='ta-confirm__label'>
                      {t('confirm.preview.section.prices.price.label')}
                    </div>
                  )}

                  <div className='ta-confirm__label'>
                    {t('confirm.preview.section.prices.price.label')}:
                  </div>
                  {(!service.discountedPrice && !service.extraPersonsPerParticipant) && <Price price={service.price} />}
                  {((!service.discountedPrice && service.extraPersonsPerParticipant > 0) &&
                    <>
                      <Price price={(service.extraPersonsPerParticipant + 1) * service.price} />
                      &nbsp;
                      <span className='ta-confirm__icon__price-calculated flex'>
                        ({(service.extraPersonsPerParticipant + 1)}&nbsp;x&nbsp;<Price className='price' price={service.price} />)
                      </span>
                    </>
                  )}
                  {((service.discountedPrice && !service.extraPersonsPerParticipant) &&
                    <>
                      <strike className='ta-services__price'><Price price={service.price} /></strike>
                      <FontAwesome5 icon='long-arrow-right' type='regular' />
                      <Price price={service.discountedPrice} className='ta-services__discounted__price' />
                    </>
                  )}
                  {((service.discountedPrice && service.extraPersonsPerParticipant > 0) &&
                    <>
                      <strike className='ta-services__price'><Price price={(service.extraPersonsPerParticipant + 1) * service.price} /></strike>
                      <FontAwesome5 icon='long-arrow-right' type='regular' />
                      <Price price={(service.extraPersonsPerParticipant + 1) * service.discountedPrice} />
                      &nbsp;
                      <span className='ta-services__discounted__price ta-confirm__icon__price-calculated flex'>
                        ({(service.extraPersonsPerParticipant + 1)}&nbsp;x&nbsp;<Price className='price' price={service.discountedPrice} />)
                      </span>
                    </>
                  )}

                </div>
                {hasStripeActive && !hasStripeFeesNotAllowed && hasStripeConnected && !!service.transactionFeePrice && (
                  <div className='ta-confirm__row__price-data ta-confirm__row__price-data__no-flex'>
                    <div className='ta-confirm__row__transaction-fee__label'>
                      {t('confirm.preview.section.transaction.fee.label')}
                    </div>
                    <ul className='ta-confirm__row__transaction-fee__gateway-list'>
                      <li>Stripe (<Price price={service.transactionFeePrice} />)</li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            {(customerFieldsWithValues && Object.keys(customerFieldsWithValues).length > 0 &&
              <div>
                <LinedTitle label={t('confirm.preview.section.customfields.title')} />

                <div className='ta-confirm__rows_container'>
                  {customerFieldsWithValues.map((item, index) => {
                    return (
                      <CustomerFieldPreview key={`customer-field-${index}`} item={item} />
                    )
                  })}
                </div>
              </div>
            )}

          </div>

          {(shouldDisplayDisclaimer &&
            <div className='ta-disclaimer'>
              {dangerousHTML(disclaimerText, false, true)}
            </div>
          )}

        </div>
      )
      )}
    </div>
  )
}

Confirm.propTypes = {
  isActive: PropTypes.bool
}

export default Confirm
