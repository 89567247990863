import React from 'react'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { connect, handlers } from '../../Store'
import { Slots, CoursesSlots } from '../../Beauties'
import { convertMinutesToHours, getDiscountedPrice } from '../../Utils'
import { format, getCustomTimeFormat } from '../../Common'

const SlotsWrapper = props => {
  const {
    appMode,
    companies,
    selectedCompanyId,
    serviceSlots,
    coursesSlots,
    selectedMinutes,
    availabilityOnDays,
    selectedDays,
    selectedEventId,
    selectedServiceId,
    selectedCourseId,
    resources,
    offers,
    services,
    timezones,
    selectedTimezoneCode,
    attributes,
    isAvailabilityActive,
    timezone
  } = props
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  let { settings, addOns } = company || {}
  settings = settings || {}
  addOns = addOns || []
  const selectedTimezone = timezones.find(tz => tz.code === selectedTimezoneCode)
  let { dateRange = null, isEnterprise } = attributes || {}
  isEnterprise = !!isEnterprise
  const selectedDate = selectedDays[0] || moment().format('YYYY-MM-DD')
  const { selectedId: selectedResourceId } = resources || {}
  const isMrSpex = isEnterprise && addOns && addOns.includes('MRSPEX')
  const utcOffers = offers.map(offer => ({
    ...offer,
    from: momenttz.tz(offer.from, company.timezone).utc().format('YYYY-MM-DD HH:mm'),
    until: momenttz.tz(offer.until, company.timezone).utc().format('YYYY-MM-DD HH:mm')
  }))
  let filteredSlots
  // Service
  if (!selectedCourseId) {
    const service = services.find(item => item.id === selectedServiceId) || {}
    filteredSlots = serviceSlots
      .filter(item => selectedDays.includes(item.day))
      .map(item => ({
        ...item,
        hasOffer: utcOffers.findIndex(offer => offer.serviceIds.includes(selectedServiceId) && offer.from.split(' ')[0] <= item.day && offer.until.split(' ')[0] >= item.day) > -1,
        slots: item.slots.map(slot => {
          let time = convertMinutesToHours(slot.minutes)
          const customTimeFormat = getCustomTimeFormat()
          if (customTimeFormat) time = format(time, customTimeFormat, { isUTC: true, format: 'LT' })
          const date = `${item.day} ${time}`
          const utcDate = momenttz.tz(date, timezone).utc().format('YYYY-MM-DD HH:mm')
          const offer = utcOffers.find(offer => offer.serviceIds.includes(selectedServiceId) && offer.from <= utcDate && offer.until >= utcDate)
          return {
            ...slot,
            day: item.day,
            price: service.price,
            discountedPrice: offer ? getDiscountedPrice(service.price, offer.discount) : null,
            offerId: offer ? offer.id : null,
            date
          }
        })
      }))
  }
  // Offer
  if (selectedCourseId) {
    const slots = {}
    coursesSlots.forEach(item => {
      const date = `${item.day} ${item.time}`
      const utcDate = momenttz.tz(date, timezone).utc().format('YYYY-MM-DD HH:mm')
      const offer = utcOffers.find(offer => offer.courseIds.includes(selectedCourseId) && offer.from <= utcDate && offer.until >= utcDate)
      slots[item.day] = {
        ...slots[item.day],
        day: item.day,
        hasOffer: !!offer
      }
      slots[item.day].slots = slots[item.day].slots || []
      const splitStartDate = item.startDate.split(' ')
      slots[item.day].slots.push({
        ...item,
        day: item.day,
        price: item.price,
        discountedPrice: offer ? getDiscountedPrice(item.price, offer.discount) : null,
        offerId: offer ? offer.id : null,
        date,
        sortingDate: `${splitStartDate[0] || item.day}T${splitStartDate[1] || '00:00'}:00Z`
      })
    })
    filteredSlots = Object.keys(slots)
      .map(index => {
        slots[index].slots.sort((a, b) => new Date(a.sortingDate) - new Date(b.sortingDate))
        return slots[index]
      })
      .sort((a, b) => new Date(a.day) - new Date(b.day))
  }
  if (settings.isWidgetWeeklyView && filteredSlots.length > 0 && filteredSlots.length < 7) {
    const dayInWeek = moment(filteredSlots[0].day).startOf('week')
    let dateForCheck
    for (let index = 0; index < 7; index++) {
      dateForCheck = dayInWeek.clone().add(index, 'days').format('YYYY-MM-DD')
      // eslint-disable-next-line
      if (!filteredSlots.find(slot => slot.day === dateForCheck)) filteredSlots.push({ day: dateForCheck, hasOffer: false, slots: [] })
    }
    filteredSlots = filteredSlots.sort((a, b) => new Date(a.day) - new Date(b.day))
  }
  if (settings.isWidgetWeeklyView && isMrSpex) {
    // don't show past days in weekly view for MSX
    const now = moment()
    filteredSlots = filteredSlots.filter((slot) => {
      slot = slot || {}
      const isBeforeToday = now.isSameOrBefore(slot.day, 'days')
      return isBeforeToday
    })
  }

  // Prepare dateRange dates
  if (dateRange && dateRange[0]) dateRange[0] = moment(dateRange[0])
  if (dateRange && dateRange[1]) dateRange[1] = moment(dateRange[1])
  const availabilityAfterDate = (selectedDays[0] && selectedDays[0]) || moment().format('YYYY-MM-DD')
  const availabilityDays = (availabilityOnDays[selectedResourceId || 'ALL'] || [])
    .sort((a, b) => new Date(a) - new Date(b))
    .filter(item => !dateRange || (dateRange && dateRange[0] && dateRange[0].isValid() && dateRange[1] && dateRange[1].isValid() && moment(item).isSameOrAfter(dateRange[0]) && moment(item).isSameOrBefore(dateRange[1])))
    .filter(item => moment(item) > moment(availabilityAfterDate))
    .filter((item, index) => index < 6)
  const onClickSlot = (day, minutes, offerId) => {
    handlers.slotSelect({ day, minutes, offerId })
  }
  const onClickCourseSlot = (eventId, day, minutes, offerId) => {
    handlers.slotForCourseSelect({ eventId, day, minutes, offerId })
  }
  const onClickOtherDay = (selectedDays) => {
    handlers.availabilityPopulate({ selectedDays })
    handlers.slotsGet()
  }
  // TODO: find another way
  // if (selectedCourseId && availabilityDays.length === 1) {
  //   // if we have just 1 future event for a specific course id, we should preselect and display it
  //   // onClickOtherDay(availabilityDays)
  //   handlers.availabilityPopulate({ selectedDays: availabilityDays })
  // }

  return (
    selectedCourseId
      ? <CoursesSlots
          appMode={appMode}
          slotsByDays={filteredSlots}
          selectedDate={selectedDate}
          selectedEventId={selectedEventId}
          onClickSlot={onClickCourseSlot}
          availableDays={availabilityDays}
          onClickOtherDay={onClickOtherDay}
          isWidgetWeeklyView={settings.isWidgetWeeklyView}
          hideResourcesSection={settings.hideResourcesSection}
          selectedTimezone={selectedTimezone}
          isAvailabilityActive={isAvailabilityActive}
        />
      : <Slots
          appMode={appMode}
          slotsByDays={filteredSlots}
          selectedDate={selectedDate}
          selectedMinutes={selectedMinutes}
          onClickSlot={onClickSlot}
          availableDays={availabilityDays}
          onClickOtherDay={onClickOtherDay}
          isWidgetWeeklyView={settings.isWidgetWeeklyView}
          selectedTimezone={selectedTimezone}
          isAvailabilityActive={isAvailabilityActive}
        />
  )
}

const maps = state => ({
  appMode: state.app.mode || 'NORMAL',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  serviceSlots: state.slots.list || [],
  coursesSlots: state.slots.coursesList || [],
  selectedMinutes: state.slots.selectedMinutes || null,
  selectedEventId: state.slots.selectedEventId || [],
  selectedDays: state.availability.selectedDays || [],
  availabilityOnDays: state.availability.onDays || {},
  selectedServiceId: state.services.selectedId || null,
  selectedCourseId: state.courses.selectedId || null,
  resources: state.resources || {},
  offers: state.offers.list || [],
  services: state.services.list || [],
  timezones: state.timezones.list || [],
  selectedTimezoneCode: state.timezones.selectedCode || null,
  attributes: state.attributes || {},
  timezone: state.timezones.selectedCode
})

export default connect(maps)(SlotsWrapper)
