import * as Sentry from '@sentry/react'
import { q } from '../../API'
import { payloads$, actions, store, handlers } from '../../../Store'
import { getRouteQuery, upsellingSDK, mrspexSDK, isDefined } from '../../../Utils'
import { UNIQUE_ENTERPRISE_ID, SUPPORTED_LOCALES, SENTRY_KEY, SENTRY_PROJECT } from '../../../Settings'
import { timezoneFormTransform, servicesTransform, coursesTransform } from '../App/utils'

payloads$(actions.COMPANY_SELECT)
  .subscribe(async ({ id, goToNext }) => {
    if (id === null) {
      const { enterprise: enterpriseAccount } = store.getState()
      const enterpriseCustomisationCSS = isDefined(enterpriseAccount, 'widgetCustomisation.payload.settings.cssOverwriteUrl', true) ||
        false

      handlers.appCustomisationCSS(enterpriseCustomisationCSS)
      handlers.appConsentMessageHide()
      return
    }

    handlers.appLoaderShow()

    const initState = store.getState()
    const initRouter = initState.router || {}
    const initialAttr = initState.attributes || []
    const sessionId = initState.app.sessionId || ''
    const { query: initQuery = {} } = initRouter
    const params = {}
    params.companyId = initQuery.accountId || initQuery.accountid
    params.externalCompanyId =
      initQuery.companyExternalId ||
      initQuery.companyexternalid ||
      initQuery.isExternalId ||
      initQuery.isexternalid
    if (params.externalCompanyId) {
      params.enterpriseId = initQuery.enterpriseId || initQuery.enterpriseid
      params.enterpriseSlug =
        initQuery.externalEnterpriseId ||
        initQuery.externalenterpriseid ||
        initQuery.enterpriseExternalId ||
        initQuery.enterpriseexternalid ||
        initQuery.enterpriseSlug
    }
    if (initQuery.resourceIds) params.filterByResourceIds = initQuery.resourceIds.split(',')
    if (initQuery.serviceIds) params.filterByServiceIds = initQuery.serviceIds.split(',')
    if (initQuery.courseIds) params.filterByCourseIds = initQuery.courseIds.split(',')
    if (initQuery.customisationId) params.customisationId = initQuery.customisationId

    // If there have parameters to filter by resources or resource categories we must append them to the params
    if (initialAttr) {
      if (initialAttr.filterResources && initialAttr.filterResources.length > 0) {
        params.filterByResourceIds = initialAttr.filterResources
      }
      if (initialAttr.filterResourceCategories && initialAttr.filterResourceCategories.length > 0) {
        params.filterByResourceCategoryIds = initialAttr.filterResourceCategories
      }
      if (initialAttr.filterResourceCategoriesbyExternalId && initialAttr.filterResourceCategoriesbyExternalId.length > 0) {
        params.filterByResourceExternalCategoryIds = initialAttr.filterResourceCategoriesbyExternalId
      }
      if (initialAttr.filterByResourceExternalIds && initialAttr.filterByResourceExternalIds.length > 0) {
        params.filterByResourceExternalIds = initialAttr.filterByResourceExternalIds
      }
      if (initialAttr.filterByServiceIds && initialAttr.filterByServiceIds.length > 0) {
        params.filterByServiceIds = initialAttr.filterByServiceIds
      }
      if (initialAttr.filterByServiceExternalIds && initialAttr.filterByServiceExternalIds.length > 0) {
        params.filterByServiceExternalIds = initialAttr.filterByServiceExternalIds
      }
    }

    // If there is no id set we must get the company based on query parameters
    if (id) {
      if (params.companyId) delete params.companyId
      if (params.externalCompanyId) delete params.externalCompanyId
      params.companyId = id
    }

    const routeQuery = getRouteQuery(initRouter)
    const result = (await q('getOnlineDataCompany', { params, sessionId })) || {}
    let {
      account,
      partner,
      error,
      company,
      widgetCustomisation
    } = result || { error: true }
    if (error) {
      error = error || {}
      const { code, data } = error
      const { customMessage, locale } = data || {}
      if (code === 'CompanyNotOnlineBookable' && customMessage) handlers.companyCustomUnavailableMessageSet(customMessage)
      handlers.navigateToPath(`/not-found${routeQuery}`)
      handlers.languageChange(initQuery.locale || locale || 'en-gb')
      handlers.appLoaderHide()
      return
    }
    if (!company) {
      handlers.navigateToPath(`/not-found${routeQuery}`)
      handlers.languageChange('en-gb')
      handlers.appLoaderHide()
      return
    }
    let { enterprise } = company || {}
    enterprise = enterprise || {}

    // If we have special customisation we need to apply it
    widgetCustomisation = widgetCustomisation || company.widgetCustomisation || enterprise.widgetCustomisation
    if (widgetCustomisation) handlers.customisationsPopulate(widgetCustomisation)

    // Load selected resource
    const stateNew = store.getState()
    const { attributes, timezones, app } = stateNew
    const { list: timezonesList } = timezones || {}
    const { isGuestBookingOpened } = app || {}
    const {
      resourceId: preselectedResourceId,
      guestFirstName = '',
      guestLastName = '',
      guestEmail = '',
      guestPhone = '',
      guestPhoneCountry = '',
      eventId = null,
      secret = null,
      isEnterprise = false,
      showResources = null,
      timezoneId,
      displayWeeklyView = null,
      showTimezone = null,
      showGuestBookingForm = null
    } = attributes
    if (preselectedResourceId) handlers.resourcePreSelect({ id: preselectedResourceId })
    let services = []
    let serviceCategories = []
    let courses = []
    let courseCategories = []
    let customerFields = []
    let customerFieldCategories = []
    let offers = []
    let timezone = ''
    let selectedCompany = company

    services = servicesTransform(services.concat(company.services.map(item => ({ ...item, companyId: company.id }))))
    serviceCategories = serviceCategories.concat(company.serviceCategories.map(item => ({ ...item, companyId: company.id })))
    courses = coursesTransform(courses.concat(company.courses.map(item => ({ ...item, companyId: company.id, isCourse: true }))))
    courseCategories = courseCategories.concat(company.courseCategories.map(item => ({ ...item, companyId: company.id })))

    // TODO: move this to transformation function
    customerFields = customerFields.concat(company.customerFields.map(item => {
      const customerFieldCategory = company.customerFieldCategories.find(customerFieldCat => customerFieldCat.id === item.categoryId)
      item.categoryOrderIndex = (customerFieldCategory && customerFieldCategory.orderIndex) || 99999

      switch (item.defaultId) {
        case 'firstName':
          if (guestFirstName && guestFirstName !== '') item.value = guestFirstName
          break
        case 'lastName':
          if (guestLastName && guestLastName !== '') item.value = guestLastName
          break
        case 'email':
          if (guestEmail && guestEmail !== '') item.value = guestEmail
          break
        case 'mobilePhone':
          if (guestPhone && guestPhone !== '') {
            if (!item.value) item.value = {}
            item.value.number = guestPhone
          }
          if (guestPhoneCountry && guestPhoneCountry !== '') {
            if (!item.value) item.value = {}
            item.value.country = guestPhoneCountry
            // } else {
            //   if (!item.value) item.value = {}
            //   item.value.country = countryPhoneCode
          }
          break
        default:
          break
      }

      return ({
        ...item,
        value: (item.defaultId === 'lastName' && guestLastName) || item.value,
        companyId: company.id
      })
    }))
    customerFieldCategories = customerFieldCategories.concat(company.customerFieldCategories.map(item => ({ ...item, companyId: company.id })))
    offers = offers.concat(company.offers.map(item => ({ ...item, companyId: company.id })))
    timezone = company.timezone
    // if timezoneId parameter is present we must overwrite the company timezone with this timezone
    if (timezoneId) {
      timezone = (timezonesList.find(item => item.code === timezoneId) || {}).code || company.timezone
      if (timezone) company.timezone = timezone
    }

    // If isEnterprise parameter is present we must change some company settings
    if (isEnterprise && company.onlineSettings) {
      company.onlineSettings.isWidgetWeeklyView = true
      company.onlineSettings.hasFacebookLogin = false
      company.onlineSettings.hasTimifyLogin = false
      company.onlineSettings.hasGuestBooking = true
    }

    // if showGuestBookingForm parameter is present we should not show Facebook and Timify options
    if (showGuestBookingForm === true) {
      company.onlineSettings.hasFacebookLogin = false
      company.onlineSettings.hasTimifyLogin = false
      company.onlineSettings.hasGuestBooking = true
    }

    // Overwrite hideResourcesSection with a parameter
    if (showResources === true || showResources === false) {
      company.onlineSettings.hideResourcesSection = !showResources
    }

    // Overwrite isWidgetWeeklyView with a parameter
    if (displayWeeklyView === true || displayWeeklyView === false) {
      company.onlineSettings.isWidgetWeeklyView = displayWeeklyView
    }

    // Overwrite showWidgetTimezoneSelector with a parameter
    if (showTimezone === true || showTimezone === false) {
      company.onlineSettings.showWidgetTimezoneSelector = showTimezone
    }

    // Track widget init
    if (selectedCompany && window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({
        action: 'timify:widget:loaded',
        company: (selectedCompany && selectedCompany.name) || '',
        companyId: (selectedCompany && selectedCompany.id) || '',
        companyExternalId: (selectedCompany && selectedCompany.externalId) || null
      }, '*')
    }

    const { onlineStatus: selectedCompanyOnlineStatus = {} } = selectedCompany || {}
    const { isOnline: selectedCompanyIsOnline = false } = selectedCompanyOnlineStatus
    if (selectedCompany && !selectedCompanyIsOnline) {
      handlers.navigateToPath(`/booked-out${routeQuery}`)
      handlers.languageChange(selectedCompany.locale)
      handlers.appLoaderHide()
      return
    }

    // Check if isGuestBookingForm parameter is true, because it will prevent showGuestBookingForm to work
    if (isGuestBookingOpened) {
      handlers.appCloseGuestBooking()
    }

    // Check mode of the app. If there have eventId & secret we need to validate them and if they are valid
    // we will preselect the service and continue into RESCHEDULE mode
    if (selectedCompany && eventId && secret) {
      const rescheduledEvent = (await q('getOnlineEventStatus', {
        companyId: selectedCompany.id,
        region: selectedCompany.region,
        eventId: eventId,
        secret: secret
      })) || {}
      const { error: rescheduledEventError } = rescheduledEvent || { rescheduledEventError: true }
      if (rescheduledEventError) {
        if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
          Sentry.captureMessage(`COMPANY_SELECT getOnlineEventStatus error: ${rescheduledEventError.code}`)
        }
        handlers.conflictErrorPopulate(rescheduledEventError.code || null)
      }
      if (!rescheduledEventError) {
        handlers.bookingRescheduledDataPopulate(rescheduledEvent)
        const isCombination = rescheduledEvent.combination && rescheduledEvent.combination.serviceId
        if (!isCombination && rescheduledEvent.service && rescheduledEvent.service.id) handlers.attributesUpdate({ serviceId: [rescheduledEvent.service.id] })
        if (isCombination) handlers.attributesUpdate({ serviceId: [rescheduledEvent.combination.serviceId] })
        if (rescheduledEvent.resources && rescheduledEvent.resources[0] && rescheduledEvent.resources[0].id) handlers.resourcePreSelect({ id: rescheduledEvent.resources[0].id })
        handlers.appUpdateMode('RESCHEDULE')
      }
    }

    if (company.enterprise) handlers.enterprisePopulate(company.enterprise)
    if (partner) handlers.partnerPopulate(partner)
    if (account) handlers.accountPopulate(account)
    if (selectedCompany && selectedCompany.id) handlers.companySelectId(selectedCompany.id)
    handlers.formSet('availability', timezoneFormTransform(timezone))
    handlers.servicesPopulate({ services, serviceCategories })
    handlers.coursesPopulate({ courses, courseCategories, miscCourseCategory: { id: 'miscCourseCategory', name: 'miscCourseCategory', orderIndex: 9999999999, companyId: selectedCompany.id } })
    handlers.customerFieldsPopulate({ customerFields, customerFieldCategories })
    handlers.offersPopulate(offers)
    handlers.companiesPopulate([company])
    handlers.appTrackingCheck()

    const state = store.getState()
    const { customisations } = state
    const { locale = null, addOns = [], enterprise: companyEnterprise = {} } = selectedCompany || {}
    const customisationCSS = isDefined(customisations, 'payload.settings.cssOverwriteUrl', true) ||
      isDefined(selectedCompany, 'widgetCustomisation.payload.settings.cssOverwriteUrl', true) ||
      isDefined(selectedCompany, 'enterprise.widgetCustomisation.payload.settings.cssOverwriteUrl', true) ||
      false
    const { locale: attributesLocale } = attributes || {}

    // If there have selected company we must load it's styles
    if (selectedCompany && selectedCompany.id) {
      handlers.appCustomisationCSS(customisationCSS)
    } else {
      // Otherwise we must check for enterprise styles
      const enterpriseCustomisationCSS = isDefined(customisations, 'payload.settings.cssOverwriteUrl', true) ||
        isDefined(company.enterprise, 'widgetCustomisation.payload.settings.cssOverwriteUrl', true) ||
        false
      handlers.appCustomisationCSS(enterpriseCustomisationCSS)
    }
    const loadAttributeLocale = attributesLocale && SUPPORTED_LOCALES.includes(attributesLocale.toLowerCase())
    if (locale && locale.length === 5 && !loadAttributeLocale) handlers.languageChange(locale)
    if (loadAttributeLocale) handlers.languageChange(attributesLocale)
    if (timezone) handlers.timezoneSelect(timezone)

    if (addOns.length > 0) {
      // After the company is selected we need to check for UpSelling addon installed. If it exist we need to
      // get all relations between services in the company.
      if (addOns.includes('UPSELLING')) {
        await upsellingSDK.getRelations(selectedCompany.id, selectedCompany.region)
          .then((response) => {
            const { categories_services: upsellingCategoriesServices = [], services: upsellingServices = [] } = response
            handlers.upsellingPopulate({ categories: upsellingCategoriesServices, services: upsellingServices })
          })
      }

      // After the company is selected we need to check for MrSpex addon installed. If it exist and there is no
      // isEnterprise variable we need to execute one additional endpoint to set last use of the widget for the company
      if (addOns.includes('MRSPEX') && !isEnterprise) {
        try {
          await mrspexSDK.widgetInit(id, selectedCompany.region)
        } catch (e) { }
      }
    }

    // Check and if need load Google Tracking for Unique companies
    if (companyEnterprise && companyEnterprise.id && UNIQUE_ENTERPRISE_ID.includes(companyEnterprise.id)) {
      try {
        if (document.getElementById('unique-gtm-js')) {
          let uniqueScript = document.getElementById('unique-gtm-js')
          uniqueScript.parentNode.removeChild(uniqueScript)
        }

        let script = document.createElement('script')
        script.setAttribute('id', 'unique-gtm-js')
        script.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7255FM');"
        document.head.appendChild(script)
      } catch (e) { }
    }

    if (!id) {
      handlers.appRun()
    } else {
      handlers.appLoaderHide()
    }

    if (goToNext) handlers.navigateToPath(`/services${routeQuery}`)
  })
