import React from 'react'
import { Stripe } from '../../../Beauties'
import { Price, t } from '../../../Common'

import './PaymentStripe.css'

const PaymentStripe = props => {
  const {
    isActive,
    isPrevious,
    selectedService,
    showFooterBackButton,
    locale,
    clientSecret,
    stripePromise
  } = props
  const classNames = ['ta-step', 'ta-payments__credit-card']
  if (isActive) classNames.push('active')
  if (isPrevious) classNames.push('prev')
  const { totalPrice } = selectedService || {}

  return (
    <div className={classNames.join(' ')}>
      {(isActive || isPrevious) && clientSecret && (
        <div className='ta-payments__stripe-elements'>
          <div className='ta-payments__stripe-elements__title'>
            {t('payments.stripe.checkout.title')} <strong><Price price={totalPrice} /></strong>
          </div>
          <Stripe showFooterBackButton={showFooterBackButton} locale={locale} clientSecret={clientSecret} stripePromise={stripePromise} />
        </div>
      )}
    </div>
  )
}

export default PaymentStripe
