import React from 'react'
import { connect, store, handlers } from '../../Store'
import { CustomerFieldsOnService } from '../../Beauties'
import { sortByCategoryOrderIndex, sortByOrderIndex, sortBy, upsellingSDK } from '../../Utils'

const CustomerFieldsOnServiceWrapper = props => {
  const {
    routeName,
    customerFields,
    customerFieldCategories,
    selectedServiceCustomerFieldIds,
    selectedCourseCustomerFieldIds,
    timezones,
    companies,
    selectedCompanyId,
    booker,
    fileLoading,
    setFileLoading
  } = props
  const company = companies.find(item => item.id === selectedCompanyId) || {}
  const selectedItemCustomerFieldIds = selectedServiceCustomerFieldIds || selectedCourseCustomerFieldIds
  const filteredCustomerFields = customerFields
    .filter(item => selectedItemCustomerFieldIds.includes(item.id))
    .sort(sortByOrderIndex)
    .sort(sortBy('internalId', false, true)) // put globals last, but ignore default data fields, bc they will not get ordered by orderIndex if you apply this sort without last param
    .sort(sortByCategoryOrderIndex)
  const isBooker = booker && booker.id

  const onSubmit = async cb => {
    const {
      selectedCompanyId,
      companies,
      upsellingSelectedIds,
      selectedServiceId,
      services,
      serviceCategories
    } = props
    const selectedCompany = companies.find(company => company.id === selectedCompanyId) || {}

    if (selectedServiceId) {
      if (upsellingSelectedIds && upsellingSelectedIds.length > 0) {
        handlers.appLoaderShow()
        const upsellingService = await upsellingSDK.createGhostService(selectedCompanyId, selectedCompany.region, {
          mainServiceId: selectedServiceId,
          additionalServicesIds: upsellingSelectedIds
        })

        if (upsellingService && upsellingService.id) {
          // We must add the service to the data fields based on data fields of all selected services
          const upsellingServices = [selectedServiceId, ...upsellingSelectedIds]
          const upsellingDataFields = [...customerFields]
          upsellingDataFields.map(customerField => {
            if (customerField.serviceIds) {
              const intersection = upsellingServices.filter(serviceId => customerField.serviceIds.includes(serviceId))
              if (intersection && intersection.length > 0) {
                customerField.serviceIds.push(upsellingService.id)
              }
            }
            return null
          })
          handlers.customerFieldsPopulate({ customerFields: upsellingDataFields, customerFieldCategories })

          // We must add the ghost service to the store & select it before go to the next screen to get availability
          services.push({ ...upsellingService, companyId: selectedCompanyId })
          await handlers.servicesPopulate({ services, serviceCategories })
          await handlers.serviceSelectedChange(upsellingService.id)
        } else {
          // TODO: We must show error !!
          handlers.appLoaderHide()
          return
        }

        handlers.appLoaderHide()
      }
    }

    const form = store.getState().forms.customerFieldsOnService
    handlers.serviceFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }
  const hide = ['confirm', 'confirmParticipants'].includes(routeName)

  return (
    !hide && (
      <CustomerFieldsOnService
        filteredCustomerFields={filteredCustomerFields}
        timezones={timezones}
        onSubmit={onSubmit}
        plan={company.plan}
        setFileLoading={setFileLoading}
        fileLoading={fileLoading}
        isBooker={isBooker}
      />
    )
  )
}

const maps = state => ({
  routeName: (state.router && state.router.name) || '',
  companies: state.companies.list || [],
  selectedCompanyId: state.companies.selectedId || null,
  customerFields: state.customerFields.list || [],
  customerFieldCategories: state.customerFields.categoriesList || [],
  selectedServiceCustomerFieldIds: state.services.selectedCustomerFieldIds || null,
  selectedCourseCustomerFieldIds: state.courses.selectedCustomerFieldIds || null,
  timezones: state.timezones.list || [],
  services: state.services.list || [],
  serviceCategories: state.services.categoriesList || [],
  selectedServiceId: state.services.selectedId || null,
  upsellingSelectedIds: state.upselling.selectedIds || [],
  booker: state.account.booker || null
})

export default connect(maps)(CustomerFieldsOnServiceWrapper)
